import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, Link, Image } from 'components';
import * as styles from './404.module.scss';

const FourOhFour = ({ location }) => {
  const { fourOhFourPageSettings } = useStaticQuery(fourOhFourPageSettingsQuery);
  const { data } = fourOhFourPageSettings;
  const {
    four_oh_four_title: title,
    four_oh_four_subtitle: subtitle,
    four_oh_four_cta_link_text: ctaText,
    four_oh_four_cta_link_slug: ctaSlug,
    four_oh_four_image: fourOhFourImage,
  } = data;

  return (
    <Layout location={location}>
      <div className={`container ${styles.container}`}>
        <Image className={styles.image} image={fourOhFourImage} alt="Opps this page has gone missing" />
        <div className={styles.content}>
          {title && <div dangerouslySetInnerHTML={{ __html: title?.html }} className={styles.title} />}
          {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle?.html }} className={styles.subtitle} />}
          {ctaSlug?.url && (
            <Link className="button secondary" to="/">
              {ctaText?.text}
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
};

export const fourOhFourPageSettingsQuery = graphql`
  query fourOhFourPageSettings {
    fourOhFourPageSettings: prismicSettings {
      data {
        four_oh_four_title {
          html
          text
        }
        four_oh_four_subtitle {
          html
          text
        }
        four_oh_four_cta_link_text {
          html
          text
        }
        four_oh_four_cta_link_slug {
          url
        }
        four_oh_four_image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default FourOhFour;
